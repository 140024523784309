import shop from "../assets/shop.png";
import instagram from "../assets/instagram.png";
import tokopedia from "../assets/tokopedia.png";
import whatsapp from "../assets/whatsapp.png";
import shopee from "../assets/shopee.png";
import form from "../assets/form.png";
import vacancy from "../assets/vacancy.png";

const items = [
  {
    title: "Vacancy",
    subtitle: "Lowongan Kerja",
    image: vacancy,
    link: "https://forms.gle/qU1G1tpR38CLPdFC6",
  },
  {
    title: "Tokopedia",
    subtitle: "@morelife.sneakers",
    image: tokopedia,
    link: "https://www.tokopedia.com/morelifesneaks",
  },
  {
    title: "Shopee",
    subtitle: "morelife",
    image: shopee,
    link: "https://shopee.co.id/shop/629268528/",
  },
  {
    title: "Instagram",
    subtitle: "@morelife.gaming",
    image: instagram,
    link: "https://instagram.com/morelife.gaming",
  },
  {
    title: "WhatsApp",
    subtitle: "+62 898-8055-222",
    image: whatsapp,
    link: "https://wa.me/628988055222",
  },
  {
    title: "Membership Form",
    subtitle: "For customers only",
    image: form,
    link: "https://forms.gle/K2XhAWPXf4RPy3c36",
  }/* ,
  {
    title: "eShop",
    subtitle: "COMING SOON",
    image: shop,
    link: "",
  }, */
];

export default items;
